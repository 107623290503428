import React from "react";
import Header from "components/Header/Header";
import Top from "components/Top/Top";
import Main from "components/Main/Main";
import TabBar from "components/TabBar/TabBar";

const Home = () => {
  const getCookieValue = (cookieName: string) => {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");

    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  };

  const downLoadApp = () => {
    const fbcValue = getCookieValue("_fbc") || "";
    const fbpValue = getCookieValue("_fbp") || "";
    const urlParams = new URLSearchParams(window.location.search);
    const p0 = urlParams.get("p0");
    const p1 = urlParams.get("p1");
    const p2 = urlParams.get("p2");
    const p3 = urlParams.get("p3");
    const p4 = urlParams.get("p4");
    const p5 = urlParams.get("p5");
    const p6 = urlParams.get("p6");
    const fbclid = urlParams.get("fbclid");
    const web = urlParams.get("web");
    const webDecode = window.atob(web);
    let src = `https://storage.googleapis.com/igm/APK/Playingio_web.apk`;
    if (web) {
      src = `${webDecode}`;
    }
    if (fbclid) {
      src = `${webDecode}?af_js_web=true&af_ss_ver=2_7_1&pid=Social_facebook&af_sub1=${fbcValue}&af_sub2=${fbpValue}&af_ss_ui=true`;
    }

    const link = document.createElement("a");
    link.href = src;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Header downLoadApp={downLoadApp} />
      <Top downLoadApp={downLoadApp} />
      <Main downLoadApp={downLoadApp} />
      <TabBar downLoadApp={downLoadApp} />
    </div>
  );
};

export default Home;
