import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "Home";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/store/apps/details" element={<Home />} />
        <Route
          path="*"
          element={<Navigate to="/store/apps/details" replace />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
